<template>
  <MenuComp /> 
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav> -->
  <router-view/>
</template>

<script>
import update from './mixins/update'
import MenuComp from '@/components/MenuComp.vue'
export default {
  components: {
    MenuComp
  },
  data() {
    return {

    }
  },
  mixins: [update],
  methods: {

  },
  mounted() {

  },
  created() {
    console.log('%cVERSION 0.2.0', 'color:orange;background-color:black;font-size:20px;');
  }
}
</script>


<style lang="scss">
@import "@/assets/scss/main.scss";

body {
  background: $lightgreen;
}

#app {
  font-family: $fig;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

</style>
