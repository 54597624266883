import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  
  // Norwegian
  {
    path: '/no/introduction',
    meta: { name: '01 Introduksjon'},
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "introduction" */ '../views/no/IntroductionView.vue')
  },
  {
    path: '/no/system',
    meta: { name: '02 Systemet'},
    component: () => import(/* webpackChunkName: "system" */ '../views/no/SystemView.vue')
  },
  {
    path: '/no/presentation',
    meta: { name: '03 PowerPoint Presentasjon'},
    component: () => import(/* webpackChunkName: "presentation" */ '../views/no/PresentationView.vue')
  },
  {
    path: '/no/concept',
    name: '04 Utvidet konsept-presentasjon',
    meta: { name: '04 Utvidet konsept-presentasjon'},
    component: () => import(/* webpackChunkName: "concept" */ '../views/no/ConceptView.vue')
  },

  // English
  {
    path: '/en/introduction',
    meta: { name: '01 Introduction'},
    component: () => import(/* webpackChunkName: "introduction2" */ '../views/en/IntroductionView.vue')
  },
  {
    path: '/en/system',
    meta: { name: '02 System'},
    component: () => import(/* webpackChunkName: "system2" */ '../views/en/SystemView.vue')
  },
  {
    path: '/en/presentation',
    meta: { name: '03 PowerPoint Presentation'},
    component: () => import(/* webpackChunkName: "presentation2" */ '../views/en/PresentationView.vue')
  },
  {
    path: '/en/concept',
    meta: { name: '04 Expanded Concept Presentation'},
    component: () => import(/* webpackChunkName: "concept2" */ '../views/en/ConceptView.vue')
  },

  // Portuguese
  {
    path: '/pt/introduction',
    meta: { name: '01 Introdução'},
    component: () => import(/* webpackChunkName: "introduction2" */ '../views/pt/IntroductionView.vue')
  },
  {
    path: '/pt/system',
    meta: { name: '02 Sistema'},
    component: () => import(/* webpackChunkName: "system2" */ '../views/pt/SystemView.vue')
  },
  {
    path: '/pt/presentation',
    meta: { name: '03 Apresentação de PowerPoint'},
    component: () => import(/* webpackChunkName: "presentation2" */ '../views/pt/PresentationView.vue')
  },
  {
    path: '/pt/concept',
    meta: { name: '04 Apresentação de Conceito Expandido'},
    component: () => import(/* webpackChunkName: "concept2" */ '../views/pt/ConceptView.vue')
  },

  // Swedish
  {
    path: '/sv/introduction',
    meta: { name: '01 Introduktion'},
    component: () => import(/* webpackChunkName: "introduction2" */ '../views/sv/IntroductionView.vue')
  },
  {
    path: '/sv/system',
    meta: { name: '02 Systemet'},
    component: () => import(/* webpackChunkName: "system2" */ '../views/sv/SystemView.vue')
  },
  {
    path: '/sv/presentation',
    meta: { name: '03 PowerPoint-presentation'},
    component: () => import(/* webpackChunkName: "presentation2" */ '../views/sv/PresentationView.vue')
  },
  {
    path: '/sv/concept',
    meta: { name: '04 Utökad konceptpresentation'},
    component: () => import(/* webpackChunkName: "concept2" */ '../views/sv/ConceptView.vue')
  },


]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
