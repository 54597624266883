<template>
  <nav v-if="showMenu">
    <ul>

      <li>
        <router-link class="" :to="`/${selectedCountry.code.toLowerCase()}/introduction`">
          <div><span>01</span>{{ selectedNavItems.introduction }}</div>
        </router-link>
      </li>
      
      <li>
        <router-link class="" :to="`/${selectedCountry.code.toLowerCase()}/system`">
          <div><span>02</span>{{ selectedNavItems.system }}</div>
        </router-link>
      </li>
      
      <li>
        <router-link class="" :to="`/${selectedCountry.code.toLowerCase()}/presentation`">
          <div><span>03</span>{{ selectedNavItems.presentation }}</div>
        </router-link>
      </li>

      <li>
        <router-link class="" :to="`/${selectedCountry.code.toLowerCase()}/concept`">
          <div><span>04</span>{{ selectedNavItems.concept }}</div>
        </router-link>
      </li>

    </ul>
  </nav>

  <router-link v-if="!showMenu" to="/" class="current-page">
    <div class="page-info">

      <div class="icon">
        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevrons-left" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="#ffffff" fill="none" stroke-linecap="round" stroke-linejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
          <path d="M11 7l-5 5l5 5" />
          <path d="M17 7l-5 5l5 5" />
        </svg>
      </div>
      
      <span>{{ routeName.number }}</span>{{ routeName.name }}
    </div>
    <!-- {{ $router.currentRoute.fullPath }}<br> -->
    <!-- {{ $route.name }} -->
  </router-link>

  <a :href="fileLink" target="_blank" download v-if="showFileBox" class="file-box">
    <div class="icon">
      <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-download" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="#ffffff" fill="none" stroke-linecap="round" stroke-linejoin="round">
        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
        <path d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2" />
        <path d="M7 11l5 5l5 -5" />
        <path d="M12 4l0 12" />
      </svg>
    </div> 
  </a>

  <div v-if="pagePath === '/'" class="language-selector" @click.stop="showLanguages = !showLanguages">

    <div class="selected-lang">
      <!-- {{ selectedCountry }} -->
      <div>
          <div class="flag" v-html="selectedCountry.flag"></div>
          <div class="name">{{ selectedCountry.name }}</div>
        </div>
    </div>

    <div class="lang-container" v-if="showLanguages">
      <ul class="lang-list scrollbar">
        <li v-for="(lang, i) in countries" :key="i" @click="setLanguage(lang.code, selectedCountry = lang.code)">
          <!-- <div class="flag">{{ lang.flag }}</div> -->
          <div class="flag" v-html="lang.flag"></div>
          <div class="name">{{ lang.name }}</div>
        </li>
      </ul>
    </div>
  </div>

</template>

<script>
  export default {
    data() {
      return {
        selectedNavItems: null,
        navItems: {
          no: {
            introduction: 'Introduksjon',
            system: 'Systemet',
            presentation: 'PowerPoint Presentajson',
            concept: 'Utvidet Konsept-presentasjon',
          },
          en: {
            introduction: 'Introduction',
            system: 'The system',
            presentation: 'PowerPoint Presentation',
            concept: 'Expanded Concept Presentation',
          },
          pt: {
            introduction: 'Introdução',
            system: 'Sistema',
            presentation: 'Apresentação de PowerPoint',
            concept: 'Apresentação de Conceito Expandido',
          },
          sv: {
            introduction: 'Introduktion',
            system: 'Systemet',
            presentation: 'PowerPoint-presentation',
            concept: 'Utökad konceptpresentation',
          },
        },
        showMenu: true,
        routeName: {
          number: null,
          name: null,
        },
        showFileBox: false,
        pagePath: null,
        pageName: null,
        fileLink: null,
        files: {
          no: {
            file1: '/Give_flyer_Intro.zip',   // Intro brosjyre
            file2: '/Give_flyer_System.zip',  // System brosjyre
            file3: 'https://docs.google.com/presentation/d/1E_8BgVyd8qlaOqV_jSRi0ztZ1o1NUKilzCEx2qsINgI/edit?usp=sharing',  // Power Point Presentajson
            file4: 'https://docs.google.com/document/d/1C6RbDXYvPI_Qhd47hLPIED0E6G4bwdmO/edit?usp=sharing&ouid=103749510732115242372&rtpof=true&sd=true', // Utvidet Konsept-presentasjon
          },
          en: {
            file1: '/Eng_Give_flyer_Intro.zip',
            file2: '/Eng_Give_flyer_System.zip',
            file3: 'https://docs.google.com/presentation/d/1UhgRawj6Nxx73ohPx1X_sQXCc6Atf2zRXAlMir-s4GE/edit?usp=sharing',
            file4: 'https://docs.google.com/document/d/1nDuNoT2MquoAA9gxuNeTmgWjlNSUlZWt/edit?usp=sharing&ouid=103749510732115242372&rtpof=true&sd=true',
          },
          pt: {
            file1: '/Donor_PT_Give_flyer_Intro.zip',
            file2: '/Donor_PT_Give_flyer_System.zip',
            file3: 'https://docs.google.com/presentation/d/1ZE-f1mDaD58O3pTRf4lDVBZURbTd-lggh7qDQX4UPpY/edit?usp=sharing',
            file4: 'https://docs.google.com/document/d/1fMDMlNWiRG5o8QE09d9YTIrW7AIPfW3n/edit?usp=sharing&ouid=103749510732115242372&rtpof=true&sd=true',
          },
          sv: {
            file1: '/Eng_Give_flyer_Intro.zip',
            file2: '/Eng_Give_flyer_System.zip',
            file3: 'https://docs.google.com/presentation/d/1rfl8lVm7C-3wkAjkhKypNFdyI6R5XiqJKfPig3MmAXI/edit?usp=sharing',
            file4: 'https://docs.google.com/document/d/1nDuNoT2MquoAA9gxuNeTmgWjlNSUlZWt/edit?usp=sharing&ouid=103749510732115242372&rtpof=true&sd=true',
          },
          // donor: {
          //   file1: '/Donor_Eng_Give_flyer_Intro.zip',
          //   file2: '/Donor_Eng_Give_flyer_System.zip',
          //   file3: 'https://docs.google.com/presentation/d/e/2PACX-1vTcPVLvXi7Z_3ZdWpk8gk5XoDxwI0L5R0oZuVIla8CPdvA7Vium3RRUkUSaFB5kDirdYJh7P6G3cwH6/pub?start=false&loop=false&delayms=3000',
          //   file4: 'https://docs.google.com/document/d/1nDuNoT2MquoAA9gxuNeTmgWjlNSUlZWt/edit?usp=sharing&ouid=103749510732115242372&rtpof=true&sd=true',
          // }
        },
        showLanguages: false,
        selectedCountry: null,
        countries: [
          // // ### https://flagicons.lipis.dev/
          { "name": "Norsk", "selected": true, "dial_code": "+47", "code": "NO", "flag": `<svg xmlns="http://www.w3.org/2000/svg" id="flag-icons-no" viewBox="0 0 640 480">
              <path fill="#ed2939" d="M0 0h640v480H0z"/>
              <path fill="#fff" d="M180 0h120v480H180z"/>
              <path fill="#fff" d="M0 180h640v120H0z"/>
              <path fill="#002664" d="M210 0h60v480h-60z"/>
              <path fill="#002664" d="M0 210h640v60H0z"/>
            </svg>` 
          }, 
          { "name": "Svenska", "selected": true, "dial_code": "+47", "code": "SV", "flag": `<svg xmlns="http://www.w3.org/2000/svg" id="flag-icons-se" viewBox="0 0 640 480"><script xmlns=""/>
              <path fill="#005293" d="M0 0h640v480H0z"/>
              <path fill="#fecb00" d="M176 0v192H0v96h176v192h96V288h368v-96H272V0z"/>
            <script xmlns=""/></svg>` 
          }, 
          { "name": "English", "selected": false, "dial_code": "+1", "code": "EN", "flag": `<svg xmlns="http://www.w3.org/2000/svg" id="flag-icons-um" viewBox="0 0 640 480">
              <path fill="#bd3d44" d="M0 0h640v480H0"/>
              <path stroke="#fff" stroke-width="37" d="M0 55.3h640M0 129h640M0 203h640M0 277h640M0 351h640M0 425h640"/>
              <path fill="#192f5d" d="M0 0h364.8v258.5H0"/>
              <marker id="um-a" markerHeight="30" markerWidth="30">
                <path fill="#fff" d="m14 0 9 27L0 10h28L5 27z"/>
              </marker>
              <path fill="none" marker-mid="url(#um-a)" d="m0 0 16 11h61 61 61 61 60L47 37h61 61 60 61L16 63h61 61 61 61 60L47 89h61 61 60 61L16 115h61 61 61 61 60L47 141h61 61 60 61L16 166h61 61 61 61 60L47 192h61 61 60 61L16 218h61 61 61 61 60L0 0"/>
            </svg>` 
          }, 
          { "name": "Português", "selected": false, "dial_code": "+351", "code": "PT", "flag": `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" id="flag-icons-pt" viewBox="0 0 640 480"><script xmlns=""/>
              <path fill="red" d="M256 0h384v480H256z"/>
              <path fill="#060" d="M0 0h256v480H0z"/>
              <g fill="#ff0" fill-rule="evenodd" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width=".6">
                <path d="M339.5 306.2c-32.3-1-180-93.2-181-108l8.1-13.5c14.7 21.3 165.7 111 180.6 107.8z"/>
                <path d="M164.9 182.8c-2.9 7.8 38.6 33.4 88.4 63.8 49.9 30.3 92.9 49 96 46.4l1.5-2.8c-.6 1-2 1.3-4.3.6-13.5-3.9-48.6-20-92.1-46.4-43.6-26.4-81.4-50.7-87.3-61a6.3 6.3 0 0 1-.6-3.1h-.2l-1.2 2.2zm175.3 123.8c-.5 1-1.6 1-3.5.8-12-1.3-48.6-19.1-91.9-45-50.4-30.2-92-57.6-87.4-64.8l1.2-2.2.2.1c-4 12.2 82.1 61.4 87.2 64.6 49.8 30.8 91.8 48.9 95.5 44.2z"/>
                <path d="M256.2 207.2c32.2-.3 72-4.4 95-13.6l-5-8c-13.5 7.5-53.5 12.5-90.3 13.2-43.4-.4-74.1-4.5-89.5-14.8l-4.6 8.6c28.2 12 57.2 14.5 94.4 14.6"/>
                <path d="M352.5 193.8c-.8 1.3-15.8 6.4-37.8 10.2a381.2 381.2 0 0 1-58.6 4.3 416.1 416.1 0 0 1-56.2-3.6c-23.1-3.6-35-8.6-39.5-10.4l1.1-2.2c12.7 5 24.7 8 38.7 10.2A411.5 411.5 0 0 0 256 206a391.8 391.8 0 0 0 58.3-4.3c22.5-3.7 34.8-8.4 36.6-10.5zm-4.4-8.1c-2.4 2-14.6 6.3-36 9.7a388.2 388.2 0 0 1-55.8 4c-22 0-40.1-1.6-53.8-3.6-21.8-2.8-33.4-8-37.6-9.4l1.3-2.2c3.3 1.7 14.4 6.2 36.5 9.3a385 385 0 0 0 53.6 3.4 384 384 0 0 0 55.4-4c21.5-3 33.1-8.4 34.9-9.8zM150.3 246c19.8 10.7 63.9 16 105.6 16.4 38 .1 87.4-5.8 105.9-15.6l-.5-10.7c-5.8 9-58.8 17.7-105.8 17.4-47-.4-90.7-7.6-105.3-17v9.5"/>
                <path d="M362.8 244.5v2.5c-2.8 3.4-20.2 8.4-42 12a434 434 0 0 1-65.4 4.4 400 400 0 0 1-62-4.3 155 155 0 0 1-44.4-12v-2.9c9.7 6.4 35.9 11.2 44.7 12.6 15.8 2.4 36.1 4.2 61.7 4.2 26.9 0 48.4-1.9 65-4.4 15.7-2.3 38-8.2 42.4-12.1m0-9v2.5c-2.8 3.3-20.2 8.3-42 11.9a434 434 0 0 1-65.4 4.5 414 414 0 0 1-62-4.3 155 155 0 0 1-44.4-12v-3c9.7 6.5 36 11.2 44.7 12.6a408 408 0 0 0 61.7 4.3c26.9 0 48.5-2 65-4.5 15.7-2.2 38-8.1 42.4-12m-107 68.8c-45.6-.2-84.7-12.4-93-14.4l6 9.4a249.8 249.8 0 0 0 87.4 14.3c34.7-1 65-3.7 86.3-14.1l6.2-9.8c-14.5 6.9-64 14.6-93 14.6"/>
                <path d="M344.9 297.3a143 143 0 0 1-2.8 4c-10 3.6-26 7.4-32.6 8.4a295.5 295.5 0 0 1-53.7 5c-40.4-.6-73.5-8.5-89-15.3l-1.3-2.1.2-.4 2.1.9a286.5 286.5 0 0 0 88.2 14.5c18.8 0 37.5-2.1 52.6-4.8 23.2-4.7 32.6-8.2 35.5-9.8l.7-.4zm5.3-8.8a287.2 287.2 0 0 1-2 3.5c-5.4 2-20 6.2-41.3 9.2-14 1.9-22.7 3.8-50.6 4.3a347.4 347.4 0 0 1-94.2-14L161 289a390 390 0 0 0 95.4 14c25.5-.5 36.4-2.4 50.3-4.3 24.8-3.8 37.3-8 41-9.1a2.9 2.9 0 0 0 0-.2l2.6-1z"/>
                <path d="M350.8 237.6c.1 30-15.3 57-27.6 68.8a99.3 99.3 0 0 1-67.8 28.2c-30.3.5-58.8-19.2-66.5-27.9a101 101 0 0 1-27.5-67.4c1.8-32.8 14.7-55.6 33.3-71.3a99.6 99.6 0 0 1 64.2-22.7 98.2 98.2 0 0 1 71 35.6c12.5 15.2 18 31.7 20.9 56.7M255.6 135a106 106 0 0 1 106 105.2 105.6 105.6 0 1 1-211.4 0c-.1-58 47.3-105.2 105.4-105.2"/>
                <path d="M255.9 134.5c58.2 0 105.6 47.4 105.6 105.6S314.1 345.7 256 345.7s-105.6-47.4-105.6-105.6c0-58.2 47.4-105.6 105.6-105.6zM152.6 240c0 56.8 46.7 103.3 103.3 103.3 56.6 0 103.3-46.5 103.3-103.3s-46.7-103.3-103.3-103.3S152.6 183.2 152.6 240"/>
                <path d="M256 143.3a97 97 0 0 1 96.7 96.7 97.1 97.1 0 0 1-96.7 96.8c-53 0-96.7-43.6-96.7-96.8a97.1 97.1 0 0 1 96.7-96.7M161.6 240c0 52 42.6 94.4 94.4 94.4s94.4-42.5 94.4-94.4c0-52-42.6-94.4-94.4-94.4a94.8 94.8 0 0 0-94.4 94.4"/>
                <path d="M260.3 134h-9.1v212.3h9z"/>
                <path d="M259.3 132.8h2.3v214.7h-2.2V132.8zm-9 0h2.4v214.7h-2.3z"/>
                <path d="M361.6 244.2v-7.8l-6.4-6-36.3-9.6-52.2-5.3-63 3.2-44.8 10.6-9 6.7v7.9l22.9-10.3 54.4-8.5h52.3l38.4 4.2 26.6 6.4z"/>
                <path d="M256 223.8c24.9 0 49 2.3 68.3 6 19.8 4 33.7 9 38.5 14.5v2.8c-5.8-7-24.5-12-39-15-19-3.6-43-6-67.9-6-26.1 0-50.5 2.6-69.3 6.2-15 3-35.1 9-37.6 14.8v-2.9c1.3-4 16.3-10 37.3-14.3 18.9-3.7 43.3-6.1 69.6-6.1zm0-9.1a383 383 0 0 1 68.3 6c19.8 4 33.7 9 38.5 14.6v2.7c-5.8-6.9-24.5-12-39-14.9-19-3.7-43-6-67.9-6a376 376 0 0 0-69.2 6.2c-14.5 2.7-35.4 8.9-37.7 14.7v-2.8c1.4-4 16.6-10.3 37.3-14.3 19-3.7 43.3-6.2 69.7-6.2m-.6-46.2c39.3-.2 73.6 5.5 89.3 13.5l5.7 10c-13.6-7.4-50.6-15-94.9-14-36.1.3-74.7 4-94 14.4l6.8-11.4c15.9-8.3 53.3-12.5 87.1-12.5"/>
                <path d="M256 176.7a354 354 0 0 1 61.3 4.3c16 3 31.3 7.4 33.5 9.8l1.7 3c-5.3-3.4-18.6-7.3-35.6-10.5s-38.7-4.3-61-4.2c-25.3-.1-45 1.2-61.8 4.2a108.9 108.9 0 0 0-33.3 10.3l1.7-3.1c6-3 15.3-6.7 31.1-9.6 17.5-3.2 37.4-4.1 62.4-4.2m0-9c21.4-.2 42.6 1 59.1 4a96 96 0 0 1 30.6 10l2.5 4c-4.2-4.7-20-9.2-34.1-11.6-16.4-2.9-36.7-4-58.1-4.2a361 361 0 0 0-59.5 4.4 97.3 97.3 0 0 0-29.6 9.1l2.2-3.3c5.8-3 15.2-5.8 27-8.1a357 357 0 0 1 59.9-4.4zM308.4 284a276.4 276.4 0 0 0-52.5-4c-65.5.8-86.6 13.5-89.2 17.3l-5-8c16.8-12 52.4-18.8 94.6-18.2 21.9.4 40.8 1.9 56.6 5l-4.5 8"/>
                <path d="M255.6 278.9c18.2.3 36 1 53.3 4.2l-1.2 2.2c-16-3-33.2-4-52-4-24.3-.2-48.7 2.1-70 8.2-6.7 1.9-17.8 6.2-19 9.8l-1.2-2c.4-2.2 7-6.6 19.6-10 24.4-7 47.2-8.3 70.5-8.4m.8-9.2a327 327 0 0 1 57.3 5l-1.3 2.3a299 299 0 0 0-56-4.9c-24.2 0-49.9 1.8-73.3 8.6-7.5 2.2-20.6 7-21 10.7l-1.2-2.2c.2-3.4 11.5-7.9 21.7-10.8 23.5-6.9 49.3-8.6 73.8-8.7"/>
                <path d="m349.4 290.5-7.8 12.3-22.7-20.1-58.6-39.5-66.2-36.3-34.3-11.7 7.3-13.6 2.5-1.3 21.3 5.3 70.4 36.3 40.6 25.6L336 272l13.9 16z"/>
                <path d="M158.6 195.5c6-4 50.2 15.6 96.6 43.6 46.1 28 90.3 59.6 86.3 65.5l-1.3 2.1-.6.5c.1-.1.8-1 0-3.1-2-6.5-33.4-31.5-85.3-62.9-50.7-30.1-92.9-48.3-97-43.1zM351 290.4c3.8-7.6-37.2-38.5-88.1-68.6-52-29.5-89.6-46.9-96.5-41.7L165 183c0 .1 0-.2.4-.5 1.2-1 3.3-1 4.2-1 11.8.2 45.5 15.7 92.8 42.8 20.8 12 87.6 55 87.3 67 0 1 .1 1.2-.3 1.8l1.7-2.6z"/>
              </g>
              <g transform="translate(0 26.7)scale(1.06667)">
                <path fill="#fff" stroke="#000" stroke-width=".7" d="M180.6 211a58.7 58.7 0 0 0 17.5 41.7 59 59 0 0 0 41.8 17.6 59.4 59.4 0 0 0 42-17.4 59 59 0 0 0 17.4-41.8v-79.2l-118.7-.2z"/>
                <path fill="red" stroke="#000" stroke-width=".5" d="M183 211.1a56.4 56.4 0 0 0 16.8 40 57 57 0 0 0 40.2 16.8 56.9 56.9 0 0 0 40.2-16.6 56.4 56.4 0 0 0 16.7-40v-77H183v76.8m91-53.7v48.9l-.1 5.1a33.2 33.2 0 0 1-10 24 34 34 0 0 1-24 10c-9.4 0-17.7-4-23.9-10.2a34 34 0 0 1-10-24v-54z"/>
                <g id="pt-e">
                  <g id="pt-d" fill="#ff0" stroke="#000" stroke-width=".5">
                    <path stroke="none" d="M190.2 154.4c.1-5.5 4-6.8 4-6.8.1 0 4.3 1.4 4.3 6.9z"/>
                    <path d="m186.8 147.7-.7 6.3h4.2c0-5.2 4-6 4-6 .1 0 4 1.1 4.1 6h4.2l-.8-6.4zm-1 6.4h17c.3 0 .6.3.6.7 0 .5-.3.8-.6.8h-17c-.3 0-.6-.3-.6-.8 0-.4.3-.7.7-.7z"/>
                    <path d="M192 154c0-3.3 2.3-4.2 2.3-4.2s2.3 1 2.3 4.2H192m-5.8-9h16.3c.3 0 .6.4.6.8 0 .3-.3.6-.6.6h-16.3c-.3 0-.6-.3-.6-.7 0-.3.3-.6.6-.6zm.4 1.5H202c.3 0 .6.3.6.7 0 .4-.3.7-.6.7h-15.5c-.4 0-.6-.3-.6-.7 0-.4.2-.7.6-.7zm5-10.6h1.2v.8h.9v-.8h1.3v.9h.9v-1h1.2v2c0 .4-.2.6-.5.6h-4.4c-.3 0-.6-.2-.6-.5zm4.6 2.7.3 6.4h-4.3l.3-6.5h3.7"/>
                    <path id="pt-a" d="M191 141.6v3.4h-4v-3.4z"/>
                    <use xlink:href="#pt-a" width="100%" height="100%" x="10.6"/>
                    <path id="pt-b" d="M186.3 139h1.2v1h.9v-1h1.2v1h.9v-1h1.2v2c0 .4-.2.6-.5.6h-4.3a.6.6 0 0 1-.6-.6z"/>
                    <use xlink:href="#pt-b" width="100%" height="100%" x="10.6"/>
                    <path fill="#000001" stroke="none" d="M193.9 140.6c0-.6.9-.6.9 0v1.6h-.9z"/>
                    <path id="pt-c" fill="#000001" stroke="none" d="M188.6 142.8c0-.6.8-.6.8 0v1.2h-.8z"/>
                    <use xlink:href="#pt-c" width="100%" height="100%" x="10.6"/>
                  </g>
                  <use xlink:href="#pt-d" width="100%" height="100%" y="46.3"/>
                  <use xlink:href="#pt-d" width="100%" height="100%" transform="rotate(-45.2 312.8 180)"/>
                </g>
                <use xlink:href="#pt-d" width="100%" height="100%" x="45.7"/>
                <use xlink:href="#pt-e" width="100%" height="100%" transform="matrix(-1 0 0 1 479.8 0)"/>
                <g id="pt-f" fill="#fff">
                  <path fill="#039" d="M232.6 202.4a8.3 8.3 0 0 0 2.2 5.7 7.2 7.2 0 0 0 5.3 2.4c2.1 0 4-1 5.3-2.4a8.3 8.3 0 0 0 2.2-5.7v-10.8h-15z"/>
                  <circle cx="236.1" cy="195.7" r="1.5"/>
                  <circle cx="244.4" cy="195.7" r="1.5"/>
                  <circle cx="240.2" cy="199.7" r="1.5"/>
                  <circle cx="236.1" cy="203.9" r="1.5"/>
                  <circle cx="244.4" cy="203.9" r="1.5"/>
                </g>
                <use xlink:href="#pt-f" width="100%" height="100%" y="-26"/>
                <use xlink:href="#pt-f" width="100%" height="100%" x="-20.8"/>
                <use xlink:href="#pt-f" width="100%" height="100%" x="20.8"/>
                <use xlink:href="#pt-f" width="100%" height="100%" y="25.8"/>
              </g>
            <script xmlns=""/></svg>` 
          }, 
          // { "name": "Donor Countries", "selected": false, "dial_code": "+1", "code": "DONOR", "flag": `<svg xmlns="http://www.w3.org/2000/svg" id="Layer_2" data-name="Layer 2" viewBox="0 0 119 89">
          //     <defs>
          //       <style>
          //         .cls-1 {
          //           fill: #0e4fad;
          //           stroke-width: 0px;
          //         }

          //         .cls-2 {
          //           fill: none;
          //           stroke: #fff;
          //           stroke-linecap: round;
          //           stroke-linejoin: round;
          //           stroke-width: 2.75px;
          //         }
          //       </style>
          //     </defs>
          //     <g id="Layer_3" data-name="Layer 3">
          //       <rect class="cls-1" width="119" height="89"/>
          //     </g>
          //     <g id="Layer_5" data-name="Layer 5">
          //       <path class="cls-2" d="M27.08,44.5c0,17.95,14.55,32.5,32.5,32.5s32.5-14.55,32.5-32.5S77.53,12,59.58,12,27.08,26.55,27.08,44.5"/>
          //       <path class="cls-2" d="M29.25,33.67h60.67"/>
          //       <path class="cls-2" d="M29.25,55.33h60.67"/>
          //       <path class="cls-2" d="M57.78,12c-12.41,19.89-12.41,45.11,0,65"/>
          //       <path class="cls-2" d="M61.39,12c12.41,19.89,12.41,45.11,0,65"/>
          //     </g>
          //   </svg>`}
        ],
      }
    },
    watch:{
      /* eslint-disable */
      $route (to, from){
        // console.log(to, from);
        this.pagePath = to.path;
        // console.log('to.path', to.path);
        this.toggleMenu(to)
        this.getFile();

        let meta = this.$route.meta;
        // console.log('meta (page meta)', meta);
        // console.log('meta name(page name)', meta.name);
        this.pageName = meta.name;

        this.getFile(meta.name);
      }
    },
    methods: {
      getSelectedCountry() {
        return this.selectedCountry = this.countries.find((element) => element.selected === true)
      },
      setLanguage(langCode) {
        // console.log(langCode)

        this.countries.forEach(item => item.selected = false );

        let getCountry = this.countries.find((element) => element.code === langCode);
        this.selectedCountry = getCountry;
        // console.log('getCountry', getCountry);

        // console.log('this.navItems[langCode]', this.navItems[langCode])
        this.selectedNavItems = this.navItems[langCode.toLowerCase()];

        this.getFile();

      },
      getFile(pageName) {
        let key = this.pageName || pageName;
        console.log('this.pageName', key);

        // console.log("this.selectedCountry.code === 'NO'", this.selectedCountry.code)
        if (this.selectedCountry.code === 'NO') {
          // console.log('switch NO');
          switch (key) {
            case '01 Introduksjon':
              this.fileLink = this.files.no.file1;
              // console.log('%c 01 Introduksjon', 'color:orange;');
              break;
  
            case '02 Systemet':
              this.fileLink = this.files.no.file2;
              // console.log('02 Systemet');
              break;
  
            case '03 PowerPoint Presentasjon':
              this.fileLink = this.files.no.file3;
              // console.log('03 PowerPoint Presentasjon');
              break;
  
            case '04 Utvidet konsept-presentasjon':
              this.fileLink = this.files.no.file4;
              // console.log('04 Utvidet konsept-presentasjon');
              break;
          
            default:
              break;
          }
        }
        else if (this.selectedCountry.code === 'EN') {
          // console.log('switch EN');
          switch (key) {
            case '01 Introduction':
              this.fileLink = this.files.en.file1;
              // console.log('%c 01 Introduksjon', 'color:orange;');
              break;
  
            case '02 System':
              this.fileLink = this.files.en.file2;
              // console.log('02 Systemet');
              break;
  
            case '03 PowerPoint Presentation':
              this.fileLink = this.files.en.file3;
              // console.log('03 PowerPoint Presentasjon');
              break;
  
            case '04 Epanded Concept Presentation':
              this.fileLink = this.files.en.file4;
              // console.log('04 Utvidet konsept-presentasjon');
              break;
          
            default:
              break;
          }
        }
        else if (this.selectedCountry.code === 'PT') {
          // console.log('switch EN');
          switch (key) {
            case '01 Introdução':
              this.fileLink = this.files.pt.file1;
              // console.log('%c 01 Introduksjon', 'color:orange;');
              break;
  
            case '02 Sistema':
              this.fileLink = this.files.pt.file2;
              // console.log('02 Systemet');
              break;
  
            case '03 Apresentação de PowerPoint':
              this.fileLink = this.files.pt.file3;
              // console.log('03 PowerPoint Presentasjon');
              break;
  
            case '04 Apresentação de Conceito Expandido':
              this.fileLink = this.files.pt.file4;
              // console.log('04 Utvidet konsept-presentasjon');
              break;
          
            default:
              break;
          }
        }
        else if (this.selectedCountry.code === 'SV') {
          // console.log('switch SV');
          switch (key) {
            case '01 Introduktion':
              this.fileLink = this.files.sv.file1;
              // console.log('%c 01 Introduksjon', 'color:orange;');
              break;
  
            case '02 Systemet':
              this.fileLink = this.files.sv.file2;
              // console.log('02 Systemet');
              break;
  
            case '03 PowerPoint-presentation':
              this.fileLink = this.files.sv.file3;
              // console.log('03 PowerPoint Presentasjon');
              break;
  
            case '04 Utökad konceptpresentation':
              this.fileLink = this.files.sv.file4;
              // console.log('04 Utvidet konsept-presentasjon');
              break;
          
            default:
              break;
          }
        }
        // else if (this.selectedCountry.code === 'DONOR') {
        //   // console.log('switch EN');
        //   switch (key) {
        //     case '01 Introduction':
        //       this.fileLink = this.files.donor.file1;
        //       // console.log('%c 01 Introduksjon', 'color:orange;');
        //       break;
  
        //     case '02 System':
        //       this.fileLink = this.files.donor.file2;
        //       // console.log('02 Systemet');
        //       break;
  
        //     case '03 PowerPoint Presentation':
        //       this.fileLink = this.files.donor.file3;
        //       // console.log('03 PowerPoint Presentasjon');
        //       break;
  
        //     case '04 Epanded Concept Presentation':
        //       this.fileLink = this.files.donor.file4;
        //       // console.log('04 Utvidet konsept-presentasjon');
        //       break;
          
        //     default:
        //       break;
        //   }
        // }


      },
      toggleMenu(to) {
        // console.log(to)
        if (to.path === '/') {
          this.showMenu = true;
          this.showFileBox = false;
        }
        else if (to.path !== '/') {
          this.showMenu = false;
          this.showFileBox = true;
        }
        this.setPageInfo() 
      },
      setPageInfo() {
        let meta = this.$route.meta;
        let metaName = meta.name;
        // console.log('meta', metaName);


        if (metaName) {
          let nameSplit = metaName.split(' ');
          // console.log(nameSplit);
          let firstSeg = nameSplit[0];
          let secondSeg = nameSplit.slice(1).join(' ');
          // console.log(firstSeg, secondSeg);
          this.routeName.number = firstSeg;
          this.routeName.name = secondSeg;
        }
      }
    },
    mounted() {
      this.toggleMenu(this.$route.path);
      // console.log('this.$route', this.$route.path)
    },
    created() {
      this.getSelectedCountry();
      this.setLanguage('NO');

    }
  }
</script>

<style lang="scss">

.donor-countries {
  position: absolute;
  right: 100px;
  z-index: 100;
  // padding-top: 10px;

  width: 160px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  // gap: 20px;
  // pointer-events: none;

  @media (min-width: $break-m) {
    margin: 0 auto;
  }

  .show-countries {
    display: flex;
    justify-content: center;
    align-items: center;
    background: lighten($darkgreen, 2);
    color: white;
    cursor: pointer;
    padding: 15px;
    // border-radius: 20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }

  .countries {
    display: flex;
    flex-flow: column;
    justify-content: center;
    gap: 20px;
    background: lighten($darkgreen, 6);
    padding: 15px;
    padding-top: 30px;
    padding-bottom: 40px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    position: relative;
    z-index: -1;
    top: -16px;
    max-height: calc(100vh);
    overflow: hidden auto;

    .flag {
      width: 45px;
  
      .name {
        font-size: 1rem;
        color: white;
      }
    }
  }
}

.language-selector {
  display: inline-flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;

  z-index: 101;
  padding: 8px;
  transition: .2s ease-in-out;
  cursor: pointer;
  user-select: none;
  
  &:hover {
    .icon {
      top: 6px;
    }
  }

  .selected-lang {
    font-weight: 500;
    margin-bottom: 4px;
    margin-left: 10px;
    color: white;
    width: 80px;


    .flag {
      width: 80px;
      width: 100%;
      // width: 120px;
    }

    .name {
      white-space: wrap;
    }
  }

  .lang-container {
    position: absolute;
    top: 95px;
    right: 20px;
    overflow: hidden;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.35);
    backdrop-filter: blur(10px);

    .lang-list {
      // overflow: hidden scroll;
      list-style: none;
      // height: 40vh;
      min-height: 50px;
      margin: 0;
      padding: 0;
      width: 100%;

      li {
        padding: 15px 15px;
        width: 100%;
        display: flex;
        flex-flow: row nowrap;
        gap: 15px;
        
        &:hover {
          background:#06523c;
          background:#06523d44;
        }

        .flag {
          width: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .name {
          white-space: nowrap;
        }

      }
    }
  }
}

.file-box {
  z-index: 10;
  position: fixed;
  top: 0;
  right: 0;
  background: $darkgreen;
  padding: 0px 15px;
  border-bottom-left-radius: 20px;

  display: flex;
  flex-flow: row;
  align-items: center;
  height: 70px;

  .icon {
    svg {
      width: 40px;
    }
  }


}

.current-page {
  z-index: 10;
  position: fixed;
  left: 0;
  top: 0;
  display: inline-flex;
  text-decoration: none;
  background: $darkgreen;
  padding: 20px 15px;
  color: white;
  font-size: 1.5rem;
  height: 70px;

  border-bottom-right-radius: 20px;
  transition: .2s ease-in-out;
  cursor: pointer;

  &:hover {
    .text .icon svg {
      left: 0px;
    }
  }

  .page-info {
    display: flex;
    align-items: center;
    flex-flow: row;

    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      width: 30px;

      svg {
        position: absolute;
        transition: .2s ease-in-out;
        height: 33px;
        left: 10px;
      }
    }

    span {
      margin-left: 15px;
      font-weight: 700;
      color: $detail-color;
      padding-right: 7px;
    }
  }  
}

nav {
  position: fixed;
  left: -2px;
  top: -2px;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  padding: 0;
  border: 1px solid black;
  background: $darkgreen;

  ul {
    margin: 0;
    padding: 0;
    height: 100%;

    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;

    @media (min-width: $break-m) {
      flex-flow: row wrap;
    }

    li {
      flex: 0 0 25%;
      // height: 100%;
      width: 100%;
      display: flex;
      justify-content: flex-start;

      @media (min-width: $break-m) {
        flex: 0 0 50%;
        height: 50%;
        width: 50%;
      }
      
      a {
        display: flex;
        align-items: center;
        width: 100%;
        max-width: 100%;
        height: 100%;
        color: white;
        padding-left: 5%;
        text-decoration: none;
        font-size: 2.3rem;
        text-align: left;
        border: 1px solid $detail-color;
        background: $darkgreen;
        transition: .2s ease-in-out;

        @media (min-width: $break-m) {
          padding-left: 10%;
          font-size: 3rem;
        }      
        
        &:hover {
          background: lighten($darkgreen, 5);
        }

        div {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          
          span {
            color: $detail-color;
            padding-right: 20px;
            font-weight: 600;
          }

        }

      }

    }
  }
}
</style>
